function App() {
  return (
    <div className="main">
      <div className="app">
        <img src="./pic.png" alt="taborosi design" />
      </div>
    </div>
  );
}

export default App;
